<template>
  <ModalToolbar id="create_fund_modal" max-width="mw-800px">
    <template v-slot:header>
      <div class="modal-header" id="kt_modal_create_api_key_header">
        <h2>{{ title }}</h2>
      </div>
    </template>
    <template v-slot:body>
      <div class="modal-body scroll-y py-0 mt-8">
        <Form class="form" :validation-schema="schema" ref="fundForm">
          <!--begin::Input group-->
          <div class="mb-5 fv-row">
            <!--begin::Label-->
            <label class="required fs-5 fw-bold mb-2">Name</label>
            <!--end::Label-->

            <!--begin::Input-->
            <Field
              type="text"
              class="form-control form-control-solid"
              placeholder="Your Fund Name"
              name="name"
              v-model="fund.name"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="name" />
              </div>
            </div>
            <!--end::Input-->
          </div>
          <div class="fv-row mb-7">
            <label class="form-label fw-bolder text-dark fs-6">Status</label>
            <br />
            <el-switch
              v-model="status"
              @change="changeStatus"
              class="mt-2"
              active-text="Acquired catalogues"
              inactive-text="Equity Catalogues"
              style="
                --el-switch-on-color: #13ce66;
                --el-switch-off-color: #ff4949;
              "
            />
          </div>
          <div class="mb-5 fv-row">
            <label class="fs-5 fw-bold mb-2">Wishlist</label>
            <Field
              class="form-control form-control-lg form-control-solid"
              name="wishlist"
              as="select"
              data-placeholder="Select a Wishlist..."
              v-model="fund.wishlist"
            >
              <option value="">Select a wishlist...</option>
              <template v-for="(wishlist, i) in wishlists" :key="i">
                <option
                  :label="wishlist.name"
                  :value="wishlist._id"
                  v-if="wishlist.fundSlot === wishlistType"
                  :selected="fund.wishlist"
                >
                  {{ wishlist.name }}
                </option>
              </template>
            </Field>
          </div>
        </Form>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal-footer flex-end bg-light-grey">
        <button
          type="submit"
          class="btn me-3 btn-primary"
          ref="submitFundRef"
          @click="submitFund"
        >
          <span class="indicator-label"> Submit </span>
          <span class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <button
          ref="closeFundModal"
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </template>
  </ModalToolbar>
</template>
<script>
import { computed, ref } from "vue";
import ModalToolbar from "@/common/components/ModalToolbar.vue";
import * as Yup from "yup";
import { Field, Form, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import { createFund } from "@/api/mongoFund";
import { WishlistFundEnum } from "@/common/enums/wishlistFundEnum";

export default {
  name: "CreateFundModal",
  components: { ModalToolbar, Field, Form, ErrorMessage },
  props: {
    title: {
      type: String,
      default: "Create Fund",
    },
  },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const fundForm = ref(null);
    const status = ref(false);
    const submitFundRef = ref(null);
    const closeFundModal = ref(null);
    const wishlistType = ref(
      status.value
        ? WishlistFundEnum.ACQUIRED_CATALOGUE_LIST
        : WishlistFundEnum.EQUITY_CATALOGUE_LIST
    );
    const fund = ref({
      name: "",
      wishlist: "",
    });
    const schema = Yup.object({
      name: Yup.string().required().label("Fund Name"),
    });
    const wishlists = computed(() => {
      return store.getters["WishlistModule/getWishlists"];
    });
    // onMounted(async () => {
    //   try {
    //     loading.value = true;
    //     await store.dispatch("WishlistModule/fetchWishlists");
    //   } catch (e) {
    //     console.log(e);
    //   } finally {
    //     loading.value = false;
    //   }
    // });

    const submitFund = async () => {
      if (fund.value.name) {
        try {
          //Disable button
          submitFundRef.value.disabled = true;
          // Activate indicator
          submitFundRef.value.setAttribute("data-kt-indicator", "on");
          let payload = {
            name: fund.value.name,
          };
          if (fund.value.wishlist) {
            const selectedWishlist = wishlists.value.find(
              (obj) => obj._id === fund.value.wishlist
            );
            delete selectedWishlist._id;
            if (status.value) {
              if (payload.equityCatalogues) {
                delete payload.equityCatalogues;
              }
              payload["acquiredCatalogues"] = selectedWishlist;
            } else {
              if (payload.acquiredCatalogues) {
                delete payload.acquiredCatalogues;
              }
              payload["equityCatalogues"] = selectedWishlist;
            }
          }
          await createFund(payload);
          await store.dispatch("FundsModule/fetchFunds");
          ElNotification({
            title: "Success",
            message: "Fund added successfully",
            type: "success",
          });
          closeFundModal.value.click();
        } catch (e) {
          console.log(e);
          ElNotification({
            title: "Oops!",
            message: e.response.data.message[0],
            type: "error",
          });
        } finally {
          submitFundRef.value.removeAttribute("data-kt-indicator");
          submitFundRef.value.disabled = false;
          fund.value = {
            name: "",
            wishlist: "",
          };
        }
      } else {
        fundForm.value.setFieldError("name", "Fund Name is a required field");
      }
    };

    const changeStatus = () => {
      if (status.value) {
        wishlistType.value = WishlistFundEnum.ACQUIRED_CATALOGUE_LIST;
      } else {
        wishlistType.value = WishlistFundEnum.EQUITY_CATALOGUE_LIST;
      }
    };

    return {
      loading,
      fund,
      fundForm,
      status,
      schema,
      wishlists,
      closeFundModal,
      submitFundRef,
      wishlistType,
      changeStatus,
      submitFund,
    };
  },
};
</script>

<template>
  <div class="funds h-100 mb-10 mb-xl-10" v-loading.fullscreen.lock="loading">
    <div class="d-flex mb-5 justify-content-between" v-if="funds.length">
      <div class="fund-head-title ms-12">Funds</div>
      <div>
        <button
          data-bs-toggle="modal"
          data-bs-target="#create_fund_modal"
          class="
            btn btn-sm btn-color-muted btn-active btn-active-primary
            active
          "
        >
          + Add Fund
        </button>
      </div>
    </div>
    <div class="row g-6 g-xl-9" v-if="funds.length">
      <div class="col-md-6 col-xl-4" v-for="fund in funds" :key="fund._id">
        <div
          class="
            card
            border border-2 border-gray-300 border-hover
            h-100
            cursor-pointer
          "
        >
          <div
            class="d-flex justify-content-end w-100"
            @click="deleteFundFromDB(fund._id)"
          >
            <span
              class="
                svg-icon svg-icon-1
                p-2
                btn btn-md btn-icon btn-active-color-primary
              "
            >
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <div @click="routeToPage(`fund/${fund._id}`)">
            <div class="card-header border-top border-bottom-0">
              <div class="card-title mt-4">
                <div class="symbol symbol-50px w-80px h-80px">
                  <img
                    class="w-100 h-100"
                    src="
                    /media/avatars/blank.png
                  "
                    alt="image"
                  />
                </div>
              </div>
            </div>
            <div class="card-body p-9">
              <div class="fs-3 fw-bolder text-dark">
                {{ capitalizeEveryWordFirstLetter(fund.name) }}
              </div>
              <p class="text-gray-400 fw-bold fs-5 mt-1 mb-7"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!funds.length && !loading"
      class="d-flex flex-column justify-content-center align-items-center h-100"
    >
      <h3 class="heading">You currently have no funds.</h3>
      <a
        class="btn btn-sm btn-primary me-3"
        data-bs-toggle="modal"
        data-bs-target="#create_fund_modal"
        >Create Fund</a
      >
    </div>
  </div>
  <CreateFundModal />
</template>
<script>
import { computed, onMounted, ref } from "vue";
import CreateFundModal from "@/funds/components/CreateFundModal.vue";
import { useStore } from "vuex";
import {
  capitalizeEveryWordFirstLetter,
  routeToPage,
} from "@/utils/staticHelper";
import { deleteFund } from "@/api/mongoFund";
import Swal from "sweetalert2";

export default {
  name: "Funds",
  methods: { routeToPage, capitalizeEveryWordFirstLetter },
  components: { CreateFundModal },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const funds = computed(() => {
      return store.getters["FundsModule/getFunds"];
    });
    onMounted(async () => {
      if (!funds.value.length) {
        try {
          loading.value = true;
          await store.dispatch("FundsModule/fetchFunds");
        } catch (e) {
          console.log(e);
        } finally {
          loading.value = false;
        }
      }
    });

    const deleteFundFromDB = (fundID) => {
      Swal.fire({
        title: "Delete this Fund?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            loading.value = true;
            await deleteFund(fundID);
            Swal.fire("Deleted!", "Fund has been deleted.", "success");
            await store.dispatch("FundsModule/fetchFunds");
            loading.value = false;
          }
        })
        .catch((e) => {
          console.error(e);
          Swal.fire("Oops!", "Error in deleting fund.", "error");
        });
    };
    return { loading, funds, deleteFundFromDB };
  },
};
</script>
<style lang="scss" scoped>
.fund-head-title {
  font-weight: 700;
  color: #33647f;
  font-size: 20px;
}
</style>

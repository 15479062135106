<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div :class="['modal-dialog modal-dialog-centered', maxWidth]">
      <div class="modal-content bg-light-grey">
        <slot name="header"></slot>
        <slot name="body"></slot>
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalToolbar",
  props: {
    id: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: "mw-1000px",
    },
  },
};
</script>
